html {
  font-size: 10px;
}

body {
  padding-top: 70px;
  background-color: #000;
}

input {
  display: block;
  width: 100%;
  margin-bottom: 3rem;
  font-size: 1.8rem;
  border-radius: 5rem;
  padding: 1rem 2rem;
}

.container {
  padding: 3rem 10%;
}

.card * {
  color: white;
}

.navbar {
  height: 70px;
}

#filter {
  width: 135px;
}

#language-container {
  min-width: 10rem; 
}

.icon-container {
  display: flex;
  margin-bottom: 2rem;
}

.frantic-logo{
  width: 70%;
  max-width: 350px;
  margin-bottom: 30px;
}
h5 {
  font-size: 3rem;
  margin: 0;
  margin-bottom: 2rem;
}

h3 {
  font-size: 25px;
  color: #fff;
}

p {
  font-size: 1.8rem;
  line-height: 1.5;
}

a {
  color: rgb(0, 157, 197);
}

a:hover {
  text-decoration: none;
  color: rgb(255, 204, 0);
}

.card-title {
  font-weight: 700;
}

.logo-container {
  padding: 80px 0;
}

.bg-dark {
  background-color: #111 !important;
}

@media (min-width: 0px) {
  .card-columns {
      column-count: 1;
  }
  .icon-container {
      max-width: 4rem; 
  }
}

@media (min-width: 700px) {
  .card-columns {
      column-count: 1;
  }
}

@media (min-width: 900px) {
  .card-columns {
      column-count: 2;
  }
}

@media (min-width: 1300px) {
  .card-columns {
      column-count: 3;
  }
}

.icon-container img {
  padding-left: 1rem;
}

/* card */
.card-box {
  margin-bottom: 3rem;
}

.card {
  border: 15px solid white;
  border-radius: 25px;
  box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.1);
  /* padding: 2rem; */
  height: 100%;
  align-self: stretch;
}

.card-container {
  padding: 2rem;
  height: 100%;
  border-radius: 1rem;
}

/* color-independent */
.color-independent {
  background: linear-gradient(to bottom, rgb(0, 157, 197), rgb(101, 179, 46),rgb(255, 204, 0), rgb(229, 0, 64));
}

.card-img, .power-img {
  max-width: 5rem;
  height: auto;
  filter:         contrast(1000%)
  invert(1);
}

.color-independent .card-img {
  filter:         contrast(1000%)
  invert(1);
}

.color-independent .card-body {
  color: white;
}

/* color-dependent */
.color-dependent {
  background: linear-gradient(90deg, rgb(0, 157, 197) 0%, rgb(0, 157, 197) 90%, rgb(101, 179, 46) 25%, rgb(101, 179, 46) 93.5%, rgb(255, 204, 0) 50%, rgb(255, 204, 0) 97%, rgb(229, 0, 64) 75%, rgb(229, 0, 64) 100%);
}

.color-dependent .card-img {
  filter:        contrast(1000%)
  invert(100%);
}

.color-dependent .card-body {
  color: white;
}

.unique {
  background: white;
  color: black;
}

.unique *, .power-card * {
  color: black !important;
}

.unique .card-img, .unique .power-img, .power-card .card-img, .power-card .power-img {
  filter: none;
}

/* event-card */
.event-card {
  background: black;
}

.event-card .card-img {
  filter: invert(100%);
}

.event-card .card-body {
  color: white;
}

/* power-card */
.power-card {
  background: rgb(255,237,0);
  border-color: #222;
}

@media all and (max-width:768px){
  label.btn.btn-secondary {
      padding: 0px;
  }

  span#basic-addon1 {
      display: none;
  }

  #language-container {
      min-width: 7rem;
  }
}